<template>
  <div class="coupon-projects" :class="{skeleton: !state.loaded }">
    <div class="title font-md">
      <b>{{ state.couponName }}</b>
    </div>
    <div class="desc font-sm">
      <span>이 쿠폰으로 이용하신 내역이에요.</span>
    </div>
    <div class="content">
      <table class="table">
        <thead>
        <tr>
          <th class="title">
            <span>이용 내역</span>
          </th>
          <th>
            <span>금액</span>
          </th>
          <th>
            <span>일시</span>
          </th>
        </tr>
        </thead>
        <template v-if="state.history.length">
          <tbody v-if="state.history.length">
          <tr v-for="(h, idx) in state.history" :key="idx">
            <td>
              <span>{{ h.useDesc }}</span>
            </td>
            <td>
              <span>{{ h.teller === "OUT" ? "-" : "" }}{{ $lib.getNumberFormat(h.pointPay) }}</span>
            </td>
            <td>
              <span>{{ h.regiDateDay }}</span>
              <br/>
              <span> {{ h.regiDateTime }}</span>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>
              <span>합계</span>
            </th>
            <th>
              <span>{{ $lib.getNumberFormat(state.remain) }}</span>
            </th>
            <th></th>
          </tr>
          </tfoot>
        </template>
        <tbody v-else>
        <tr>
          <td colspan="3" class="text-center">데이터가 없습니다.</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalCouponHistory";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "md"});

      state.remain = modalParams.remain;
      state.couponName = modalParams.couponName;

      state.loaded = false;
      http.get(`/api/coupons/${modalParams.couponId}/history`).then(({data}) => {
        state.loaded = true;

        if (Array.isArray(data?.body)) {
          state.history = data.body;
        }
      }).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));
    });

    const state = reactive({
      loaded: false,
      couponName: "",
      remain: 0,
      history: [{
        useDesc: "Please wait a moment",
        pointPay: 0,
        regiDateDay: "0000-00-00",
        regiDateTime: "00:00:00"
      }]
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.coupon-projects {
  background: #fff;
  padding: $px25;

  .title {
    margin-bottom: $px9;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .desc {
    margin-bottom: $px25;
    color: #666;
  }

  .content {
    table {
      th {
        text-align: center;
        background: $colorBackground;
        border-bottom: $px1 solid #dee2e6;

        &.title {
          width: 38%;
        }
      }

      th, td {
        font-size: $px14;
      }

      td:nth-child(2), td:nth-child(3) {
        text-align: center;
      }

      th:nth-child(3), td:nth-child(3) {
        width: 35%;
      }
    }
  }

  &.skeleton {
    .title > b,
    .desc > span {
      @include skeleton;
    }

    .content {
      th, td {
        > span {
          @include skeleton;
        }
      }
    }
  }

  @media(max-width: 991px) {
  }
}
</style>